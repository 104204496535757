import { CloseCircleOutlined } from '@ant-design/icons';
import { Typography } from 'antd'
const { Title, Text } = Typography;


const ErrorPage: React.FunctionComponent = () => {
  return (
  <div style={{ textAlign: 'center' }}>
    <Title level={1}><Text type="danger"><CloseCircleOutlined /></Text></Title>
    <Title level={3}>We are Sorry :(</Title>
    <Text>Please refresh page to try again</Text>
  </div>
  )
}

export default ErrorPage

