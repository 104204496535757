import ProfileAddressType from '../../types/profile'
import { FaBriefcase, FaLocationArrow } from 'react-icons/fa'
import { AiFillHome } from 'react-icons/ai'

const ProfileAddress: React.FunctionComponent<ProfileAddressType> = ({ type, street, street2, district, province, postalCode, country }) => {
  const icon = (type === 'work') ? <FaBriefcase /> : <AiFillHome />
  return (
    <div>{street} {street2} {district} {province} {postalCode}{country ? `, ${country}` : ''}</div>
  )
}

export default ProfileAddress


