import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';
import logo from './logo.svg';
import { Row, Col } from 'antd';
import './App.css';
import Profile from './components/profile/profile'
import TestRoute from './components/testRoute/testRoute'

const bgColor = { background: '#0092ff', padding: '8px 0', textAlign: 'center' };

function App() {
  return (
    <div className="App">
      <Row>
        <Col xs={3} sm={3} md={6} lg={7} xl={8} xxl={8}></Col>
        <Col xs={24} sm={24} md={12} lg={10} xl={8} xxl={8} className="bgColor">
          <div className="card-section">
            <Switch>
              <Route path="/profile/:matchCode/:profileId" component={Profile} />
              {/* <Route path="/sub" component={TestRoute} /> */}
              {/* <Route path="*" component={TestRoute} /> */}
            </Switch>
          </div>
        </Col>
      </Row>

      {/* <div>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/profile/ss">P'X</Link>
          </li>
          <li>
            <Link to="/profile/wp">Me</Link>
          </li>
        </ul>
      </div> */}
    </div>
  );
}

{/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx 55555222</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}

export default App;
