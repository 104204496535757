import { Skeleton, Avatar, Space, Divider, Switch, Form, Radio } from 'antd';


const ProfileSkeleton: React.FunctionComponent = () => {
  return (
  <div>
    <Skeleton.Avatar active size={128} shape="circle" />

    <div id="nameBox">
      <div className="profileBox-marginLarge">
        <Skeleton.Button style={{ width: 250 }} active size="default" shape="round" />
      </div>
      <div className="profileBox">
        <Skeleton.Button style={{ width: 200 }} active size="default" shape="round" />
      </div>
    </div>
    <br/>
    <div className="profileBox">
      <Skeleton.Button style={{ width: 30 }} active size="default" shape="round" />
    </div>
    <div className="profileBox">
      <Skeleton.Button style={{ width: 160 }} active size="default" shape="round" />
    </div>
    <br/>
    <div className="profileBox">
      <Skeleton.Button style={{ width: 30 }} active size="default" shape="round" />
    </div>
    <div className="profileBox">
      <Skeleton.Button style={{ width: 160 }} active size="default" shape="round" />
    </div>
  </div>
  )
}

export default ProfileSkeleton
