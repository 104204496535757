import { FaExternalLinkAlt } from 'react-icons/fa'
interface ProfileCard {
  value: string
  type: string
}

const ProfileFormat: React.FunctionComponent<ProfileCard> = ({ value, type }) => {
  if (type === 'tel') {
    return <a href={"tel:" + value}>{value}</a>
  } else if (type === 'url') {
    const formatUrl = value
    return <a href={formatUrl} target="_blank">{value}</a>
  } else if (type === 'email') {
    return <a href={`email:${value}`}>{value}</a>
  } else {
    return (
      <span>{value}</span>
    )
  }
}

export default ProfileFormat
