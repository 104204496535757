import { Avatar, Image } from 'antd';
import facebook from '../../asset/icons/FB_58.png'
import line from '../../asset/icons/LINE.png'
import linkedin from '../../asset/icons/LI-In.png'
import twitter from '../../asset/icons/twitter.png'
import ig from '../../asset/icons/IG.png'
interface SocialType {
  type: string
  value: string
}

const ProfileSocial: React.FunctionComponent<SocialType> = ({ type, value }) => {
  if (type === 'facebook') {
    return (<a href={value} target="_blank" rel="noreferrer"><Avatar src={facebook} className="socialIcon" size={55} /></a>)
  } else if (type === 'line') {
    return (<a href={value} target="_blank" rel="noreferrer"><Avatar src={line} className="socialIcon" size={55} /></a>)
  } else if (type === 'instagram') {
    return (<a href={value} target="_blank" rel="noreferrer"><Avatar src={ig} className="socialIcon" size={55} /></a>)
  } else if (type === 'linkedin') {
    return (<a href={value} target="_blank" rel="noreferrer"><Avatar src={linkedin} className="socialIcon" size={55} /></a>)
  } else if (type === 'twitter') {
    return (<a href={value} target="_blank" rel="noreferrer"><Avatar src={twitter} className="socialIcon" size={55} /></a>)
  } else {
    return (<a href={value} target="_blank" rel="noreferrer"><Avatar className="socialIcon" size={55}>Social</Avatar></a>)
  }
}

export default ProfileSocial