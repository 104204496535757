import {
  ApolloClient,
  createHttpLink,
  InMemoryCache
} from "@apollo/client";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URI,
});

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpLink
})

export default apolloClient
