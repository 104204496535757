import gql from 'graphql-tag';

export const GRAPH_QUERY_PROFILE = gql`
  query ($profileCode: String! $matchCode: String! $scan: String) {
    profile(
      profileCode: $profileCode
      matchCode: $matchCode
      scan: $scan
    ) {
      image
      firstName
      lastName
      nickName
      birthday
      position
      companyName
      mobilePhone
      homePhone
      workPhone
      homeEmail
      workEmail
      homepageUrl
      workUrl
      note
      vcf
      address {
        type
        street
        street2
        district
        province
        postalCode
        country
      }
      socials {
        type
        endpoint
      }
    }
  }
`;

