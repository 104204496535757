import { CloseCircleOutlined } from '@ant-design/icons';
import { Typography } from 'antd'
const { Title, Text } = Typography;


const ProfileNotFound: React.FunctionComponent = () => {
  return (
  <div style={{ textAlign: 'center' }}>
    <Title level={1}><Text type="danger"><CloseCircleOutlined /></Text></Title>
    <Title level={3}>404</Title>
    <Text>Page not found</Text>
  </div>
  )
}

export default ProfileNotFound
