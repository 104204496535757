import React, {useState, useEffect} from 'react'
import { Typography, Avatar, Divider, Button } from 'antd'
import { UserOutlined } from '@ant-design/icons';
import '../../css/profile.css'
import { useParams } from 'react-router-dom'
import ProfileField from '../profileFields/profileFields'
import ProfileAddress from '../profileAddress/profileAddress'
import { FaBirthdayCake } from 'react-icons/fa'
import { AiTwotonePhone, AiOutlineLink } from 'react-icons/ai'
import { FiPhone } from 'react-icons/fi'
import { BsEnvelope, BsPhone } from 'react-icons/bs'
import ProfileAddressType from '../../types/profile'
import ProfileSocialType from '../../types/profileSocial'
import ProfileFormat from '../profileFormat/profileFormat'
import ProfileSocial from '../profileSocial/profileSocial'
import ProfileSkeleton from '../profileSkeleton/profileSkeleton'
import ErrorPage from '../errorPage/errorPage'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import { GRAPH_QUERY_PROFILE } from '../../graphql/profile'
import ProfileNotFound from '../profileNotFound/profileNotFound';

const { Text, Link, Title } = Typography

interface RouteParams {
  profileId: string
  matchCode: string
}

function formatDisplay(str: string) {
  return `${str}`
}

const getDataFromStorage = (key: string) => {
  const val = sessionStorage.getItem(key) || null
  return val === 'null' ? null : val
}

const setDataToStorage = (key: string, value: any) => {
  sessionStorage.setItem(key, value)
  return value
}

const removeDataStorage = (key: string) => {
  return sessionStorage.removeItem(key)
}

function Profile() {
  const { profileId, matchCode } = useParams<RouteParams>()
  let fields: string[][] = []
  const scanKey = 'phy'

  // Get query string
  const params = new URLSearchParams(window.location.search)
  const fromPhy = params.get('from-phy')
  // const scanFrom = typeof fromPhy !== 'undefined' ? setDataToStorage(scanKey, fromPhy) : null
  

  const [isShowSocial, setShowSocial] = useState(false)
  const [isExpand, setExpand] = useState(false)
  const [scanFrom, setScanFrom] = useState(typeof fromPhy !== 'undefined' ? setDataToStorage(scanKey, fromPhy) : null)
  console.log('SCAN ===', scanFrom)
  const expandProfile = (stat: any) => {
    setExpand(stat)
  }
  // const [{ loading, error, data }] = useQuery(GRAPH_QUERY_PROFILE, {
  //   variables: { profileCode: profileId, matchCode: matchCode, scan: scanFrom },
  // })

  let [fetchProfile, { loading, error, data }] = useLazyQuery(GRAPH_QUERY_PROFILE, {
    variables: { profileCode: profileId, matchCode: matchCode, scan: getDataFromStorage(scanKey) }
  })

  useEffect(() => {
    console.log('scanFrom >>', scanFrom)
    if (!scanFrom) {
      fetchProfile()
      setTimeout(() => {
        removeDataStorage(scanKey)
      }, 1000)
    }
    if (scanFrom) {
      window.history.replaceState({}, '', window.location.href.split("?")[0])
      console.log('SCAN2 ===', getDataFromStorage(scanKey))
      setScanFrom(null)
    }
    if (data?.profile) {
      const haveSocial = data?.profile.socials.find((v: any) => {
        return v.endpoint !== ''
      })
      setShowSocial(haveSocial)
    }
    
  }, [data])

  if (loading)
  return <div style={{ textAlign: 'center' }}>
    <ProfileSkeleton />
  </div>
  if (error)
  return (
    <ErrorPage />
  )
  if (data?.profile === null) return <ProfileNotFound />
  return (
    <div className="Profile">
      <div className="textCenter">
        <div>
          {
            (data?.profile.image) ? 
              <Avatar
                size={128}
                src={data?.profile.image}
              />
            : <Avatar size={128} icon={<UserOutlined />}
          />
          }
        </div>

        <div id="nameBox">
          <Title level={3} className="titleName">
            {data?.profile.firstName} {data?.profile.lastName}
            <Title level={4}>{data?.profile.nickName}</Title>
            </Title>
          <Title level={4} type="secondary" className="titleName" style={{ marginTop: '-5px', marginBottom: '15px' }}>{data?.profile.position}</Title>
        </div>

        <div className="profileBox-marginLarge">
          <Title level={4} className="normalLink"><FiPhone size={27} /><br/><ProfileFormat type="tel" value={data?.profile.mobilePhone} /></Title>
        </div>

        <div className="profileBox-marginLarge">
          <Title level={4} className="normalLink"><BsEnvelope size={27} /><br/><ProfileFormat type="email" value={data?.profile.workEmail} /></Title>
        </div>

        {!isExpand ? 
          <div className="profileBox">
              <Button type="primary" shape="round" size="large" className="digixBtn" style={{ color: 'white' }} onClick={e => expandProfile(1)}>Show All</Button>
          </div>
          : ''
        }
      </div>
      <div id="extendProfile" style={ { maxHeight: isExpand ? '10000px' : '0' }}>
      {
        isExpand ? 
        <div>
          <Divider className="dividerMargin"></Divider>
          <div className="profileBox">
            <strong>{data?.profile.companyName}</strong>
            <br/>
            <Text type="secondary">
              {data?.profile.address.map((addr: ProfileAddressType) => {
                return (<ProfileAddress
                    type={addr.type}
                    street={addr.street}
                    street2={addr.street2}
                    district={addr.district}
                    province={addr.province}
                    postalCode={addr.postalCode}
                    country={addr.country}
                  />)
              })}
            </Text>
          </div>
          <div className="profileBox">
            <ul className="reset-list-bullet">
              {/* <li>W : <ProfileFormat type="url" value={data?.profile.workUrl} /></li> */}
              {/* <li>P : <ProfileFormat type="tel" value={data?.profile.workPhone} /></li> */}
              {
                data?.profile.homepageUrl ? 
                  <li><AiOutlineLink /> H : <ProfileFormat type="url" value={data?.profile.homepageUrl} /></li>
                : ''
              }
              {
                data?.profile.workUrl ? 
                <li><AiOutlineLink /> W : <ProfileFormat type="url" value={data?.profile.workUrl} /></li>
                : ''
              }
            </ul>
          </div>
          {
            data?.profile.note ? <div className="profileBox"><strong>Note</strong><br/> {data?.profile.note}</div>
            : ''
          }
        </div>
        : ''
      }
      </div>
      {
          (isShowSocial) ? 
          <div>
            <Divider className="dividerMargin"></Divider>
            <ul id="profileSocials" className="reset-list-bullet textCenter list-bullet-inline">
              {
                data?.profile.socials.map((social: ProfileSocialType) => {
                  if (social.endpoint !== null && social.endpoint !== '') {
                    return (
                      <li><ProfileSocial type={social.type} value={social.endpoint} /></li>
                    )
                  }
                })
              }
            </ul>
          </div>
          : ''
        }
      {
        isExpand ? 
          <div className="textCenter profileBox" style={{ marginTop: '50px' }}>{
            (data?.profile.vcf) ?
              <a href={data.profile.vcf}>
                <Button ghost shape="round" size="large" className="digixBtn digixBtn-outline">
                  Add to Contact
                </Button>
              </a>
            : ''
          }
          </div>
        : ''
      }
      {/*
      <Text type="secondary"><FaBirthdayCake /> {data?.profile.birthday}</Text>
      
      <div style={ { marginTop: '100px' } }>
        <ul>
          {
            fields.map(f => {
              return <ProfileField label={f[0]} value={f[1]} type={f[2] ? f[2] : ''} />
            })
          }
        </ul>
      </div> */}
    </div>
  );
}

export default Profile;
